#header {
	position:fixed;
	.loggedIn & {
		top:31px;
	}

	.contentWrap {
		@media (max-width:@screen-sm-max) {
			max-width:none;
		}
	}

	top:0;
	left:0;
	right:0;
	z-index:95;
	border-bottom:1px solid #e4e5e8;
	background: #fff;

	@media (min-width:@screen-sm) {
		.logo {
			img {
				height:54px;
			}
			float:left;
			padding-top:33px;
			padding-bottom:11px;
			.transition(@transition);
			body.stickyHead & {
				padding-top:13px;
			}
			width:30%;
		}
	}
	@media (max-width:1023px) {
		.logo {
			width:30%;
		}
	}

	// Mobile header
	@media (max-width:@screen-sm-max) {
		background:#fff;
		position:fixed;
		z-index:@zindex-header;
		top:0;
		left:0;
		width:100%;
		height:@header-height-mobile;
		border-bottom:1px solid #e4e5e8;
		margin:0;
		.logo {
			padding-top:14px;
			img {
				height:54px;
			}
		}
	}
	#divisions {
		display:block;
		position:absolute;
		top:10px;
		right:10px;
		padding-left: 16px;
		.hidden-sm;
		.hidden-xs;

		&:before {
			position: absolute;
			left: 0;
			top: 10px;
			content: "\F105";
			font-family: "FontAwesome";
			transition: 0.5s;
		}

		ul {
			float:right;
			position:relative;

			padding: 30px 14px 0;
			background: #fff;
			width: 52px;
			height: 42px;
			overflow: hidden;
			border: 1px solid transparent;


		}
		&.open {
			&:before {
				transform: rotateZ(90deg);
			}
			ul {
				width: initial;
				height: initial;
				overflow: initial;
				padding: 30px 14px 10px;
				border: 1px solid #ccc;
				background: #fff;
			}
		}
		li {
			background: #fff;
			//float:left;
			//margin-left:6px;
			//padding-left:6px;
			//border-left:1px solid #e4e5e8;
			padding-top:10px;
			&:first-child {
				margin-left:0;
				padding-left:0;
				border-left:0;
			}
			a {
				color:@color-text;
				text-transform:uppercase;
			}
			&.selected {
				position: absolute;
				z-index: 10;
				top: 0;

				a {
					color:@color-text-dark;
					font-weight:700;
				}
			}
		}
	}
}
