.whatwedo {
	#main {
		max-width: none;
		padding: 0;

		.boxText,
		.boxTitle {
			max-width:1005px;
			padding: 0 15px;
			margin: 0 auto;

			@media (min-width:@screen-sm) and (max-width:1023px) {
				max-width:750px;
			}
		}

		h3 {
			font-size: 22px;
		}

		.box_ord_0 {
			padding-bottom:50px;

			h3 {
				margin-top: 85px;
			}
		}
	}
}