@import "../../bower_components/bootstrap/less/print.less";

@media print {
	html, body {height: 99%;margin:0;}
	a[href]:after {content:''}
	.entryContent a:after {	content:" (" attr(href) ")"}
	.frontpage a, #mainmenu a {text-decoration:none}
	.frontpage a[href]:after, .entryContent a.fancybox:after {content:''}
	#pageContainer {padding-top:0}
	body.loggedIn {padding-top:0}
	#mainmenu, #menuContainer, #footer,#admin, .jip, #footerInfo, .entryFooter, .back, #breadcrumbs, .addthis_toolbox, #mainmenuToggle, .fb-like {display:none!important;}
	#contentContainer {padding: 0;border:0;margin:0;}
	.row {margin:0;}
	#header {
		position:relative;
		height:100px;
	}

	#main {
		.make-md-column(12,0);
		left:auto;
		padding:0;
	}

	.employees #main #employees {
		.image {
			width: 200px!important;
			float:left;
			margin: 0 10px 10px 0;

			img {
				max-width:100%;
			}
		}
		.emp-description.mobile,
		.branch-description.mobile {
			display:block !important;
			max-height:99999px !important;
			opacity:1 !important;
		}

		.plus {
			display:none;
		}
		.entry {
			border:0;
		}
	}
	#hero, #qn, .owl-controls, #abendingar, .map {
		display:none;
	}
}