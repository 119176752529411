#news {
	.entry {
		border-bottom: 0;
		.firstLine {
			.clearfix();
			width: 100%;

			h2 {
				float: left;
				a {
					color: #000;
					font-weight: bold;

					&:hover {
						color: @color-first;
					}
				}
			}

			.entryInfo {
				float: right;
				color: #53565a;
				font-size: 16px;
				font-style:italic;
			}

		}
		.secondLine {

			.entryContent {
				font-size: 18px;
				line-height: 1.5;
			}

			a.more {
				font-size: 18px;
				text-transform: none;
				padding-left: 10px;
				display: inline;

				&:after {
					font-size: 14px;
					padding-left: 12px;
					color: #000;
					content: @fa-var-chevron-right;
					font-family: "FontAwesome";
				}
			}
		}
	}
}
