#mainmenuToggle {
	.visible-xs-block();
	.visible-sm-block();

	position:fixed;
	z-index:@zindex-header;
	top: 0;
	right: 0;
	cursor: pointer;
	padding: 4px 5px;
	height:52px;
	width:52px;
	margin-right:17px;
	margin-top:15px;
	background:rgba(255,255,255,0.3);
	border-radius:4px;

	.transition(@transition);
	.mainmenu-open & {
		background:#eb5f1a;
	}

	span {
		display: block;
		width:39px;
		height:7px;
		background-color: #ea4f02;
		margin: 6px auto;
		.border-radius(4px);
		.box-shadow(0 2px 2px rgba(0, 0, 0, 0.15));
		.transition(@transition);

		.mainmenu-open & {
			background-color:#fff;
		}
	}
}

#mainmenu {
	@media (min-width:@screen-md) {
		top:70px;
		a {
			color:@color-text-light;
			display:block;
			text-align:center;
			padding:6px;
		}
		.mmLevel1 {
			display: table;
			width: 70%;
			margin: 0;
			@media (max-width:1340px) {
				padding-right:80px;
			}
		}
		.mmLevel2 {
			display:none;
		}
		.level1 {
			display:table-cell;
			width:1%; // even columns
			&.ele-language {
				display:none;
			}
			> a {
				.transition(@transition);
				padding-bottom:19px;
				padding-top:65px;
				font-size:18px;
				body.stickyHead & {
					padding-top:34px;
				}
			}
			text-transform:uppercase;
			&:hover {
				a {
					color:@color-first;
				}
				background:rgba(255,255,255,0.2);
			}

			&:first-child {
				border-left:1px solid #e4e5e8;
			}
			border-right:1px solid #e4e5e8;
		}
		.selectedLevel1 {
			a {
				color:@color-first;
			}
			background:rgba(255,255,255,0.6) !important;
		}
	}

	@media (min-width:@screen-sm) and (max-width:1023px) {
		.mmLevel1 {
			width:70%;
			padding-right:0;
		}
	}

	// Mobile navigation
	@media (max-width:@screen-sm-max) {
		max-width:0;
		overflow-y:scroll;
		opacity:0;
		.transition(.3s ease all);
		body.mainmenu-open & {
			opacity:1;
			max-width:99999em;
		}

		position: fixed;
		z-index: @zindex-header;
		top: @header-height-mobile;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(255,255,255,0.9);

		a {
			text-align:left;
			color:@color-first;
			&:hover {
				color:@color-text-light;
			}
		}
		.mmLevel1 {
			display:block;
		}
		.mmLevel2 {
			display:block;
			padding-left:@grid-gutter-width;
		}
		.level1 {
			border-bottom:1px solid #fff;
			display:block;
			width:100%;
			font-size:24px;
			text-transform:uppercase;
			opacity: 0;
			.transform(translateY(-50px) scale(1.5));
			&.ele-language {
				a {
					display:inline-block;
					padding-left:12px;
					&:first-child {
						padding-left:70px;
					}
				}
			}
			> a {
				padding:16px 0 14px 70px;
				display:block;
				color:@color-text;
			}
			transition: transform 800ms cubic-bezier(0.18, 1, 0.22, 1) 0ms, opacity 850ms cubic-bezier(0.18, 1, 0.22, 1) 0ms;
			.box-shadow(0 1px 0 rgba(0, 0, 0, 0.13));

			body.mainmenu-open & {
				opacity: 1;
				.transform(translateY(0px) scale(1));
			}
			&:nth-child(1) {
				.transition-delay(70ms);
			}
			&:nth-child(2) {
				.transition-delay(140ms);
			}
			&:nth-child(3) {
				.transition-delay(210ms);
			}
			&:nth-child(4) {
				.transition-delay(280ms);
			}
			&:nth-child(5) {
				.transition-delay(350ms);
			}
			&:nth-child(6) {
				.transition-delay(420ms);
			}
			&:nth-child(7) {
				.transition-delay(490ms);
			}
			&:nth-child(8) {
				.transition-delay(560ms);
			}
			&:nth-child(9) {
				.transition-delay(630ms);
			}
			&:nth-child(10) {
				.transition-delay(700ms);
			}
		}

		.selectedLevel1 {
			> a {
				color:@color-first;
			}
		}
	}
}

#footerMenu {
	max-width:480px;
	float:left;
	@media(min-width:@screen-md) {
		width:50%;
	}
	@media (max-width:1023px) {
		width:60%;
	}
	@media (max-width:@screen-sm-max) {
		display:none;
	}

	.mmLevel1 {
		width:100%;
		display:table;
	}

	.level1 {
		font-size:14px;
		line-height:36px;
		text-transform:uppercase;
		text-align:center;
		display:table-cell;
		position:relative;
		width:1%;
		.transition(@transition);
		> a {
			padding-top:70px;
			padding-bottom:5px;
			display:block;
			color:#a7a8aa;
			.transition(@transition);
		}
		&:after,
		&:before {
			content:'';
			position:absolute;
			right:0;
			top:0;
			z-index:10;
			display:block;
			width:1px;
			height:100%;
			background:#e4e5e8;
			opacity:1;
			.transition(@transition);
		}
		&:before {
			left:-1px;
		}
		&:hover {
			&:after,
			&:before {
				opacity:1;
			}
			background:rgba(255,255,255,0.1);
			> a {
				color:@color-first;
			}
		}
	}

	.selectedLevel1 {
		background:rgba(255,255,255,0.6) !important;
		> a {
			color:@color-first;
		}
		&:after,
		&:before {
			opacity:1;
		}
	}
}
