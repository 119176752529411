.map-canvas {
	border-radius:5px;
}

.map-wrapper {
	width: 100%;
	height:@map-height-md !important;

	@media (max-width:@screen-xs-max) {
		height:410px !important;
	}
}

.map {
	.clearfix;
}

.map-items {
	color:#fff;
	li {
		padding:9px;
		border: 1px solid rgb(167, 168, 170);
		border-radius: 4px;
		font-weight:700;
		font-size: 18px;
		float:left;
		margin-top:10px;
		margin-right:20px;
		.transition(@transition);
		&:hover {
			color:@color-first;
		}
		.title {
			display: inline-block;
		}
		&:after {
			margin-left: 10px;
			display: inline-block;
			.fa-icon;
			content:@fa-var-map-marker;
			float: right;
			color:@color-first;
			font-size: 22px;
		}
	}
}

.gm-style-iw {
	padding:10px 5px 10px;
	font-size:18px !important;
	line-height:1.4;
	p {
		margin-bottom:0;
	}
}
