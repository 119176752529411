#owl-hero {
	.frontpage & {
		height: 100vh !important;
		.down {
			display: none;
		}

		.wrapper {
			padding-bottom: 0;
			bottom: 6%;
		}
	}

	.frontpage & .item {
		height: 100vh;
	}

	.clearfix;

	height:64vh;
	overflow:hidden;

	.owl-stage-outer {
		position:fixed;
	}

	.owl-stage-outer,
	.owl-stage,
	.owl-item {
		height:100%;
	}

	.item {
		position: relative;
		height:64vh;
		width:100%;
		overflow:hidden;
		background-position:center center;
		background-repeat:no-repeat;
		background-size:cover;
	}

	.wrapper {
		position: absolute;
		bottom: 4%;
		left: 0;
		right: 0;
		@media (max-height: 720px) {
			bottom: 6%;
		}
	}

	.jip {
		display:inline-block;
		position:relative;
	}

	.text,
	.down {
		padding:0 15px;
		margin: 0 auto;
		max-width: 1005px;
		@media (min-width:@screen-sm) and (max-width:1023px) {
			max-width:750px;
		}
	}

	.text {
		padding-bottom: 12px;
		margin:0 auto;
		color:#fff;
		font-size:45px;
		line-height:1;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.2);

		@media (max-width:@screen-xs-max) {
			font-size:27px;
		}
	}

	.line {
		display: block;
		position: absolute;
		background:@color-first;
		left: 0;
		width: 35%;
		height: 3px;
	}

	.down {
		padding-top: 23px;
		@media (max-width:@screen-xs-max) {
			display:none;
		}
		@media (max-height: 720px) {
			display: none;
		}
		z-index:9;
		height:90px;
		a {
			display:inline-block;
			background:rgba(255,255,255,0.2);
			text-align: center;
			width:50px;
			height:50px;
			border-radius:10px;
			font-size:25px;
			line-height:48px;
			color:#ea4f02;
			.transition(@transition);
			&:hover {
				color:#fff;
				background:@color-first;
			}
			i {
				text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
			}
		}
		.transition(@transition);
		&.away {
			.transform(translateY(60px));
			opacity:0;
		}

	}
}
