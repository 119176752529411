.ui-datepicker.ui-widget-content {
	padding: 20px;
	background: #303030;
	border: 0;
	border-radius: 5px;
	color: #fff;
	width: 340px;
	z-index: 20 !important;

	.ui-state-default {
		background: none;
		border: 0;
		color: #fff;
		text-align: center;
		font-size: 15px;
	}

	.ui-state-hover {
		background: #fff;
		color: #303030;
	}

	.ui-datepicker-today a {
		color: #fff;
		background: #666;
	}

	.ui-state-active {
		background: @color-first;
		color: #fff;
	}
	td  {
		span, a {
			padding: 4px;
		}
	}

	.ui-datepicker-title {
		margin: 0 2.3em;
		line-height: 22px;
		text-align: center;
		color: #fff;
		font-size: 20px;
		font-weight: normal;
		text-transform: uppercase;
	}

	.ui-datepicker-header {
		padding: 6px 0;
		border: 0;
		background: none;
		margin-bottom: 10px;
	}

	th {
		padding: 4px 2px;
		text-align: center;
		font-weight: normal;
		border: 0;
		font-size: 14px;
		text-transform: uppercase;
		border-bottom: 1px solid #707070;
	}

	.ui-datepicker-prev, .ui-datepicker-next {
		display: block;
		width: 25px;
		height: 25px;
		top: 4px;
		background: none;
		border: 0;
		cursor: pointer;

		span {
			display: none;
		}
	}

	.ui-datepicker-prev {
		left: 5px;

		&:after {
			.rotate(135deg);
			margin-left: 8px;
		}
	}

	.ui-datepicker-next {
		right: 5px;

		&:after {
			.rotate(-45deg);
			margin-right: 8px;
		}
	}

	.ui-datepicker-prev:after, .ui-datepicker-next:after {
		content: '';
		display: block;
		width: 15px;
		height: 15px;
		border: 2px solid #fff;
		border-top: 0;
		border-left: 0;
		margin-top: 5px;
	}

	select.ui-datepicker-month,
	select.ui-datepicker-year {
		background: #303030;
		color: #fff;
		border: 1px solid #404040;
	}
}