.employees #footerInfo {
	margin-top: 0;
}

.employees #employees {
	.clearfix;
	padding-bottom:40px;

	@media (max-width:@screen-xs-max) {
		padding-bottom:80px;
	}
	.staff {
		float:none;
		width:auto;
		display: flex;
		> div {
			display: flex;
			flex:1;
		}

		@media (max-width:@screen-xs-max) {
			display: block;
		}
	}

	h1 {
		margin-bottom:22px;
	}

	.employee {
		flex:1;
		margin-bottom: 30px;
		margin-left:auto;
		margin-right:auto;
		background:#e4e5e8;
		.image {
			position:relative;
			overflow:hidden;
			img {
				width:100%;
			}
		}
	}

	.branch-description,
	.boxTitle {
		max-width: 975px;
		margin: 0 auto;
	}

	.branch-description {
		padding-bottom: 90px;
	}

	.emp-description {
		padding:23px 15px 13px;
		h3 {
			font-size:16px;
			margin-top:0;
			margin-bottom:6px;
		}
		.jobTitle {
			margin-bottom: 11px;
		}
		.last-word {
			margin-top: 3px;
			display: block;
		}

		ul {
			padding-left: 0;
			list-style: none;

			li {
				padding-left: 10px;
				&:before {
					.fa-icon;
					content:@fa-var-circle;
					font-size: 3px;
					line-height: 16px;
					display: inline-block;
					margin-top: -3px;
					margin-right: 7px;
					margin-left: -9px;
					vertical-align: middle;
				}
			}
		}
		color:#53565a;
	}
}

#findus {
	padding:143px 0 100px;
	.clearfix;
	position:relative;
	z-index:1;
	background:@color-third;
	color:#fff;
	h3 {
		font-size:22px;
		margin-bottom:50px;
		padding:0 15px;
	}
	ul {
		list-style:none;
		padding-left:0;
		margin-bottom:30px;
		.make-sm-column(6);
		.make-md-column(3);
		p {
			margin-bottom:0;
		}
		@media (max-width:@screen-sm-max) {
			&:nth-child(3n+1) {
				clear:left;
			}
		}
	}
}

#our-team-lower {
	position: relative;
	z-index:1;
	.contentWrap {
		margin: 0 auto;
		max-width:1005px;
		padding: 50px 15px 80px;
		@media (min-width:@screen-sm) and (max-width:1023px) {
			max-width:750px;
		}
	}


	background:@color-second;
	color:#fff;
	h3 {
		margin-bottom: 20px;
		font-size: 22px;
	}
	p img {
		width: 100%;
		border-radius: 10px;
		display: block;
		margin: 0 auto;
		margin-bottom: 30px;
	}
}

@keyframes fadeInDown2 {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -30px, 0);
		transform: translate3d(0, -30px, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
