body.loggedIn {
	padding-top: 31px;
}

/** ADMIN BAR */

#admin {
	font-family:Verdana,sans-serif;
	font-size:11px;
	color:#333;
	line-height:1.5;
	position:fixed;
	top:0;
	left:1%;
	width:98%;
	max-width: 98%;
	height:31px;
	padding:0;
	z-index:100;
	border-top:0;
}
#admin, #admin .container {
	-webkit-transition: max-width .5s ease-in-out;
	-moz-transition: max-width .5s ease-in-out;
	-ms-transition: max-width .5s ease-in-out;
	-o-transition: max-width .5s ease-in-out;
	transition: max-width .5s ease-in-out;
}
#admin ul {
	padding-left:16px;
	margin:0;
	list-style:none;
}
#admin li {
	float:left;
	position:relative;
	height:30px;
	line-height:32px;
	padding-right:8px;
}
#admin a {
	color:#333;
	text-decoration:none;
	background:none;
	display:block;
	border:0;
	font-weight:300;
}
#admin a:hover {
	text-decoration:underline;
}
#admin .image {
	float:left;
	margin:2px 4px 0 8px;
	padding:0;
}
#admin .image img {
	border:0;
	padding:0;
	margin:0;
}

#admin .icon {
	border-left:1px solid #ccc;
}
#admin .icon > a {
	padding-left:32px;
}

#admin .usermenu {
	right:2px;
	position:absolute;
}
#admin .usermenu .icon {
	padding-left:0;
}
#admin .usermenu .user {
	border: 0;
	background:url('/_moya/icons/User.png') no-repeat 0 50% !important;
	padding-left:20px;
}
#admin .usermenu .division {
	background:url('/_moya/icons/Hierarchy.png') no-repeat 10px 50% !important;
	padding-left:32px;
}
#admin .usermenu .division select {
	padding: 3px;
	margin-top:2px;
	width: auto;
	min-width: 0;
	min-height: 0;
	height: 20px;
}
#admin .modulemenu {
	position:absolute;
	right:-20px;
	top:28px;
	background:#fff;
	padding:8px 0;
	border:1px solid #aaa;
	color:#fff;
	z-index:99;
	width:170px;
}
#admin .modulemenu li,
#admin .quickmenu ul li{
	margin:0;
	padding:0;
	border:0;
	line-height:18px;
	height:auto;
	float:none;
}
#admin .modulemenu a,
#admin .quickmenu ul a {
	padding:2px 16px;
	width:auto;
	white-space:nowrap;
}
#admin .modulemenu li a{
	padding-left:32px;
}

#admin .drop {
	background:url('/_moya/images/admin/admin_action.png') no-repeat 0 0;
	padding:0 18px 0 24px;
	border-left:1px solid #ccc;
}
#admin .drop li a {
	display:block;
	line-height:1.5;
}
#admin .drop li a:hover {
	padding-right:0;
}

#admin .action {
	padding-right:0;
}
#admin .action {
	background:url('/_moya/images/admin/admin_action.png') no-repeat 0 0;
	padding-right:10px;
}

#admin .quickmenu ul {
	position:absolute;
	left:0;
	top:26px;
	background:#fff;
	padding:8px 0;
	border:1px solid #aaa;
	color:#fff;
	z-index:99;
}
#admin .quickmenu li {
	float:left;
	padding-top:0;
}
#admin .quickmenu ul img {
	border:0;
	vertical-align:middle;
	margin-right:4px;
}

#admin .actionmenu a {
	background:none;
}

#admin .logo {
	float:left;
	background: url('//static.stefna.is/moya/logo/tiny/116_grey_retina.png') no-repeat 50% 70%;
	background-size: 71px 22px !important;
	width:90px;
	height:31px;
	position:relative;
	z-index:2;
}

#admin .logo:hover {
	cursor:pointer;
}
#admin .quickmenu, #admin .usermenu {
	-webkit-transition: opacity .2s ease-in-out;
	-moz-transition: opacity .2s ease-in-out;
	-ms-transition: opacity .2s ease-in-out;
	-o-transition: opacity .2s ease-in-out;
	transition: opacity .2s ease-in-out;
	-webkit-transition-delay: .3s; /* Safari */
	transition-delay: .3s;
	opacity: 1;
}
#admin.collapsed .quickmenu, #admin.collapsed .usermenu {
	-webkit-transition-delay: 0;
	-moz-transition-delay: 0;
	-ms-transition-delay: 0;
	-o-transition-delay: 0;
	transition-delay: 0;
	opacity: 0;
}
#admin.collapsed {
	max-width: 90px;
}
#admin .container {
	max-width: 100%;
	position: relative;
	max-height: 31px;

	/*FIX FOR BOOTSTRAP CLASSNAME CONFLICT */
	width:auto !important;
	margin:0 !important;
	padding:0 !important;
}
#admin.collapsed .container {
	max-width: 0;
	overflow:hidden;
}
.division-sort {
	list-style-type: none;
}
.division-sort li {
	border: 2px solid #ddd;
	padding: 5px;
	margin-bottom: 5px;
}
#grid-division .greyed {
	color: #999;
}

#growl {
	position:absolute;
	top:31px;
	right:0;
	width:460px;
	background-color:#fff;
	background-color:rgba(250,250,250,0.4);

}
#growl .message {
	display:none;
}

/** LOGIN SCREEN */

#auth {
	width: 320px;
	margin: 0 auto;
	padding: 40px 0;
	text-align: center;

	dl {
		text-align: left;
	}

	dd {
		margin-bottom: 8px;
	}

	.text {
		&:extend(input[type="text"].headline);
	}

	.boxTitle h1 {
		margin-bottom: 40px;
	}

	.lostPassword {
		font-size: 10px;
	}
}

/** JIPS **/

.jip {
	text-align:left;
}
.jip.list {
	margin-bottom:10px;
	overflow:auto;
}
.jip.list a {
	margin-right:10px;
	font-weight:400;
	font-size:11px;
	display:block;
	line-height:16px;
	float:left;
	padding: 6px;
}
.jip a {
	background:none;
	text-decoration:none;
	text-transform:none;
}
.jip ul {
	font-weight:400;
	font-size:11px;
	list-style:none outside;
	width:180px;
	position:absolute;
	margin:0;
	padding:2px;
	border:1px solid #aaa;
	background:#fff;
	z-index:50;
	margin-top:-10px;
}
.jip li {
	margin:0;
	padding:0;
	border:0;
	background:none;
}
.jip ul a {
	display:block;
	padding:2px;
	border:1px solid #fff;
}
.jip ul a:hover {
	background:#f6f7f8;
	border:1px solid #eee;
	color:#468;
	text-decoration:none;
}
.jip .iconLink {
	margin-right:0;
}
.entry .jip.list {
	margin-top:10px;
}
.jip .icon {
	width: auto !important;
	height: auto !important;
}

/** HELP **/

.helpIcon {
	float:right;
}
.helpIcon img {
	border:0;
}
#help-modal {
	padding-top:20px;
}
#help-modal h4 {
	font-weight:400;
	font-size:13px;
	color:#000;
	margin:0 0 10px 0;
}
#help-modal p {
	font-size:11px;
	color:#666;
	margin-bottom:30px;
}

/** CPANEL **/

.cpanel {
	.make-row();

	ul {
		list-style:none;
		margin: 0;
		.make-sm-column(6);
		.make-xs-column(12);

		@media (max-width: @screen-sm) {
			&:first-child li:first-child {
				border-top: 1px solid #ccc;
				padding-top: 10px;
			}
		}
	}

	li {
		min-height:60px;
		border-bottom: 1px solid #ccc;
		margin-bottom: 10px;
		padding-bottom: 10px;

		&:first-child {
			border-top: 1px solid #ccc;
			padding-top: 10px;
			
			@media (max-width: @screen-sm) {
				border-top: 0;
				padding-top: 0;
			}
		}
	}

	a {
		text-decoration: none;
		font-size: 13px;
	}

	small {
		display: block;
		color: #444;
		font-size: 12px;
		margin-bottom: 10px;
	}

	img {
		float: left;
		margin: 4px 10px 10px 0;
	}
}

/** MODULE SPECIFIC **/

/*adverts*/
#adman dl.information {
	margin-left:16px;
}

/*boxman*/
#boxman_menu {
	width:200px;
	float:left;
}
div.boxman_category {
	background-color:#ddd;
	padding: 5px;
}
#boxman_menu ul, #boxman_sections ul {
	list-style:none;
	margin:0 0 20px 0;
	padding: 0;
}

li.boxman_template, div.boxman_section {
	background-color:#eee;
	padding:5px;
}

li.boxman_template {
	cursor:pointer;
}

div.boxman_template_title {
	float:left;
}
span.boxman_template_url, span.boxman_template_section {
	display:none;
}
span.boxman_template_icons {
	float:right;
}
span.boxman_template_icons .icon {
	padding-left:2px;
	cursor:pointer;
}
#boxman_sections {
	float:left;
	width:300px;
	float:left;
	margin-left:40px;
}
ul.boxman_section_boxes {
	min-height: 60px;
	background-color:#fff;
}
li.boxman_section_box {
	background-color:#eee;
	padding:8px;
	margin: 0 0 2px 20px;
}

#dialog p.error {
	font-size:10px;
	background-color:#F8CACA;
	border:1px dotted #73000E;
}

.boxman_hide {
	color:#aaa;
}

.boxmanBoxForm #title-element {
	float:left;
}

.boxmanBoxForm .clearTitle {
	clear:left;
	border-bottom:1px dotted #000;
	margin:2px 0 2px 0;
}

.boxmanBoxForm .boxman_show_title {
	float:left;
	font-size:0.8em;
}

.boxmanBoxForm .boxman_show_title .lmnt {
	float:left;
}

.boxmanBoxForm #poll_id-label {
	clear:left;
}

/*mail*/
.paginationControl {text-align:center;}
.paginationControl .active {
	font-weight: bold;
}
#template_image img {
	border:1px solid #CCCCCC;
	color:#FFFFFF;
	padding:5px;
	float:right;
}
#mail #listpicker {
	margin-bottom:1.5em;
}
#mail #item_preview {
	font-weight:700;
}

#mail #previewOverlay {
	background-color:#000;
	opacity:0.5;
	height:100%;
	left:0;
	position:fixed;
	top:0;
	width:100%;
	z-index:100;
	display:none;

}
#mail #preview {
	display:none;
	top:20%;
	text-align:left;
	z-index:102;
	position:fixed;
	height:500px;
	width:800px;
	background:#fff;
	overflow:hidden;
}

#mail #previewTitle {
	text-align:right;
	background-color: #2E2E2D;
	line-height:2;
	padding: 0 5px;
}
#mail #previewTitle a {
	color:#eee;
	font-weight:bold;
}
#mail #previewContent {
	overflow:scroll;
	height:490px;
}

/*settings*/
#settings form .description {
	display:inline;
	font-size:10px;
}
#settings form dd {
	clear:left;
}
#settings form > dl > dt {
	display:none;
}
#settings form > dl > dd > fieldset {
	border:0;
	border-top:2px solid #ccc;
	padding:0 20px;
}
#settings form > dl > dd > fieldset > legend {
	font-size:14px;
	cursor:pointer;
}
#settings fieldset {
	border:0;
	border-top:2px solid #ccc;
}
#settings .diff {
	padding:5px 20px;
	display:inline;
	font-weight:400;
}
#settings .diff a {
	font-size:10px;
	color:#888;
}
#settings #cont-news dd {
	margin-bottom:0 !important;
}
#settings #news-itemCount-label,
#settings #news-itemCount-element {
	float:left;
	clear:none;
	line-height:28px;
}
#settings #news-itemCount-element {
	margin:0 0 0 10px !important;
}

#settings h3.ui-accordion-header {
	margin-bottom: 0;
}
/*news*/
#news .categories {
	display:block;
}
#news #news_categories {
	height:191px;
	padding-bottom:10px;
	overflow-y:scroll;
}

/** Responsive Admin Bar **/
div#admin .quickmenu li a.expand {
	display: none;
}
@media only screen and (max-width: 1200px) and (min-width: 960px) {
	div#admin .usermenu .user {
		display: none;
	}
}
@media only screen and (max-width: 959px) {
	.grid {
		width: 100%;
		overflow-y: auto;
		margin: 0 0 1em;
		position: relative;
		-webkit-overflow-scrolling: touch;
	}
	.grid table {
		width: 960px;
	}
	.grid table .jip {
		position: relative;
	}
	.grid table .jip ul {
		top: 16px;
		right: 10px;
	}
	#admin .container {
		max-height: none !important;
		max-width: none !important;
	}
	div#admin.collapsed {
		max-height: 39px;
	}
	div#admin {
		width: 98% !important;
		max-width: 98% !important;
		background-position: 50% -35px;
		height: auto;
		max-height: none;
		overflow: hidden;
	}

	#admin .quickmenu, #admin .usermenu {
		-webkit-transition: none !important;
		-moz-transition: none !important;
		-ms-transition: none !important;
		-o-transition: none !important;
		transition: none !important;
		opacity: 1 !important;
	}

	div#admin .logo {
		height: 39px;
		position: relative;
		z-index: 2;
	}
	div#admin .usermenu {
		top: 3px;
		right: 15px;
	}
	div#admin .usermenu .config,
	div#admin .usermenu .user {
		display: none;
	}
	div#admin .usermenu .division {
		border-left: 0;
	}
	div#admin .quickmenu {
		clear: both;
		padding-top: 4px;
		padding-left: 1px;
		overflow: hidden;
		font-size: 13px;
	}
	div#admin .quickmenu > li:first-child {
		border-top: 1px solid #ccc;
	}
	div#admin .quickmenu > li:last-child {
		border-bottom: none;
	}
	div#admin .quickmenu > li a {
	}
	div#admin .quickmenu > li a.expand {
		display: block;
		width: 40px;
		position: absolute;
		right: 0;
		padding-left: 0;
		text-indent: -99px;
		overflow: hidden;
		background: url('/_moya/images/asc.gif') no-repeat center 16px!important;
		margin-right: 0;
	}
	div#admin .quickmenu > li a.expand.down {
		background: url('/_moya/images/desc.gif') no-repeat center 16px!important;
	}
	div#admin .quickmenu > li.drop a.expand {
		width: 100%;
		background-position: center right!important;
		margin-right: 9px;
	}
	div#admin .quickmenu ul {
		display: none!important;
		position: relative;
		top: auto;
		background: none;
		border: 0;
		left: 16px;
	}
	div#admin .quickmenu ul.show {
		display: block!important;
	}
	#admin .quickmenu li {
		height: auto;
		background: none;
		border-left: 0;
		float: none;
		border-bottom: 1px solid #ccc;
		padding-top: 3px;
		padding-bottom: 3px;
	}
	#growl {
		max-width: 100%;
		top: 42px;
	}
	#center .boxText img.icon {
		max-width: none;
	}
}
@media only screen and (max-width: 767px) {
	.admin .w50 {
		width: 100%;
		margin-bottom: 1em;
		float: none;
	}
}
@media only screen and (max-width: 499px) {
	.grid .filter-buttons {
		float: none;
		display: block;
		margin-top: 10px;
		margin-bottom: 10px;
	}
}
@media only screen and (max-width: 399px) {
	div#admin .usermenu .logout {
		border-left: 0;
		padding-right: 0;
	}
	div#admin .usermenu .logout a {
		width: 32px;
		text-indent: -99px;
		overflow: hidden;
		padding-left: 0;
	}
}

.strevda {
	.jip {
		position: absolute;
		top: 0;
		right: 0;
		background: #fff;
		padding: 10px;
		z-index: 1;

		img {
			margin: 0;
		}
	}
}