img.responsive {
	.img-responsive();

	visibility:hidden;

	&.loaded {
		visibility:visible;
	}
}

.btn-primary {
	background:#fff;
	.transition(@transition);
	font-size:20px;
	font-weight:700;
	border-width:3px;
	margin:5px 0;

	&:hover {
		background:#f25f19;
		color:#fff;
	}

	&:active,
	&.active,
	.selected > & {
		background:@color-third !important;
		border-color:#888b8d !important;
		color:#fff !important;
	}
}

#main {
	h1 {
		margin-top: 85px;
		font-size:22px;
		margin-bottom:27px;
	}

	h2 {
		font-size:22px;
		margin-bottom:27px;
	}

	h3 {
		font-size:20px;
		margin-bottom:27px;
	}
}

.last-word {
	font-weight:700;
}

.page #main .boxText,
.whatwedo #main .boxText {
	img {
		max-width: 100%;
	}
	h4 {
		margin-top: 22px;
		margin-bottom: 6px;
	}
}

.button,
input.submit,
input.cancel,
button.cancel,
button.save_design {
	&:extend(.btn);
	&:extend(.btn-primary);

	&:hover {
		&:extend(.btn:hover);
		&:extend(.btn-primary:hover);
	}
	&:active {
		&:extend(.btn:active);
		&:extend(.btn-primary:active);
	}
}
