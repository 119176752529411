.frontpage {
	#footerInfo {
		display: none;
	}
	.box {
		display: none;
	}
}

body.frontpage {
	min-height: 100%;
}

.frontpage #main {
	.box_ord_0 {
		.contentWrap;
		.centeredContentWrap;
		@media (min-width:1024px) {
			padding:0 90px;
		}
		padding-bottom:150px !important;
	}
	.box_ord_1 {
		position:relative;
	}
	.map {
		padding:80px 0 50px;
		background:@color-third;
		.boxText,
		.boxTitle {
			.contentWrap;
		}

		.boxTitle {
			color:#fff;
			h2 {
				margin-bottom:55px;

				@media (max-width:1023px) {
					padding-left:15px;
				}

				@media (max-width:@screen-xs-max) {
					margin-bottom:30px;
				}
			}
		}
	}
}
