/** Color palette **/
@color-first:#cc5015;
@color-second:#888b8d;
@color-third:#53565a;
@color-fourth:#a7a8aa;

@color-first-dark:darken(@color-first, 9.5%);
@color-first-light:#f25f19;
@color-first-faded:fade(@color-first, 40%);
@color-second-dark:darken(@color-second, 9.5%);
@color-second-light:lighten(@color-second, 9.5%);
@color-second-faded:fade(@color-second, 80%);
@color-third-dark:darken(@color-third, 9.5%);
@color-third-light:lighten(@color-third, 9.5%);
@color-third-faded:fade(@color-third, 80%);

@color-text:@color-third;
@color-text-light:@color-fourth;
@color-text-dark:#000;

@color-border:#ccc;
@color-border-dark:darken(@color-border, 20%);
@color-border-light:lighten(@color-border, 9.5%);
@color-border-faded:fade(@color-border, 50%);


/** Z index **/
@zindex-header:10;
@zindex-header-logo:31;
@zindex-divisions:20;
@zindex-mainmenu:30;


/** Other **/
@site-width:1170px;
@header-height-mobile:83px;
@transition:.4s ease all;
@map-height-md:615px;

/** Override bootstrap vars **/
@body-bg:#fff;
@text-color:@color-text;
@brand-primary:@color-first;
@input-border-focus:@color-first-faded;

/** Links **/
@link-color:@color-first-light;
@link-hover-color:@color-first;
@link-hover-decoration:none;

/** Fonts **/
@font-family-sans-serif:'Open Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
//@font-family-condensed:"Helvetica Neue", Helvetica, Arial, sans-serif;
@font-size-base:16px;
//@line-height-base:1.5;


/** Tables **/
@table-bg:transparent;
@table-bg-accent:rgba(0, 0, 0, .2);
@table-bg-head:rgba(0, 0, 0, .5);
@table-border-color:rgba(0,0, 0, .8);


/** Global padding **/
//@padding-base-vertical:10px;
//@padding-base-horizontal:16px;


/** Global border-radius **/
@border-radius-base:4px;
//@border-radius-large:0px;
//@border-radius-small:0px;


/** Buttons **/
@btn-font-weight:400;

@btn-default-color:@color-text-dark;
@btn-default-bg:transparent;
@btn-default-border:@color-text-dark;

@btn-primary-color:@color-first;
@btn-primary-bg:transparent;
@btn-primary-border:@color-first;

@btn-info-color:#fff;
@btn-info-bg:transparent;
@btn-info-border:#fff;
