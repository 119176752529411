.pattern {
	background:url("@{path-images}pattern.png") top left no-repeat;
	height:125px;
	position:absolute;
	width:100%;
	display:block;
	z-index:@zindex-header - 1;
	top:70px;
	left:0;
	right:0;
	@media (max-width:@screen-sm-max) {
		display:none;
	}
}
.second-pattern {
	position:relative;
	top:-71px;
	margin-bottom:-75px;

	background:url("@{path-images}pattern_grey.png") bottom repeat-x;
	height:197px;
	width:100%;
	display:block;
	z-index:@zindex-header - 1;

	@media (max-width:@screen-xs-max) {
		margin-bottom:-110px;
	}
}
.third-pattern {
	position:absolute;
	top:0;
	z-index:@zindex-header - 1;

	display:block;
	height:197px;
	width:100%;

	background:url("@{path-images}pattern_grey.png") top repeat-x;
	.transform(translateY(-50%));

	@media (max-width:@screen-xs-max) {
		height:190px;
		top:125px;
		margin-top:-125px;
	}
}
