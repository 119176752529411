#footerInfo {
	position:relative;
	z-index:1;
	margin-top:50px;
	.frontpage &,
	.employees & {
		margin-top:0;
	}
	color:#a7a8aa;
	border-top: 1px solid #e4e5e8;

	@media (max-width:@screen-sm-max) {
		padding:25px 0;
	}
}

#footerContent {
	.box_ord_0 {
		font-size:14px;
		margin-top:78px;
		text-align:right;
		float:right!important;
		@media (min-width:@screen-sm) {
			width:45%;
		}
		@media (max-width:1023px) {
			width:40%;
			margin-top:59px;
		}
		p {
			margin:0;
			@media (min-width:1024px) {
				display:inline-block;
			}
		}
		a {
			margin:0 2px;
		}
		@media (max-width:@screen-sm-max) {
			margin-top:0;
			padding:5px 0;
			text-align:center;
			width:100%;
		}
	}
}
