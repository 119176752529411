body {
	overflow-y:scroll;
	min-height:101%;
	display: flex;
	flex-direction: column;
	height: 100vh;

	&#tinymce, &.mceContentBody {
		display:block;
		flex-direction: initial;
	}
}

#page {
	flex:1 0 auto;
}

#footerInfo {
	flex-shrink:0;
}

html {
	height:100%;
}

body {
	-webkit-font-smoothing:antialiased;
}

#pageContainer {
	position:relative;

	// Move content down so the mobile header does not block it
	/*@media (max-width:@screen-sm-max) {
		padding-top:@header-height-mobile;
	}*/
}

#contentContainer {
	z-index:1;
	position:relative;
	background:#fff;
	.clearfix();
}

#main {
	max-width: @site-width;
	margin:0 auto;
	@media (min-width:@screen-sm) and (max-width:1023px) {
		max-width:750px;
	}
	body.frontpage & {
		max-width:none;
		padding:0;
	}
	padding:0 15px;
}

.page #main {
	max-width:1005px;
	@media (min-width:@screen-sm) and (max-width:1023px) {
		max-width:750px;
	}
}

#sidebar {
	.make-md-column(3);

	body.hideright &, body.forsida & {
		&:extend(.hidden);
	}

	.box {
		.make-xs-column(6);
		.make-md-column(12);
	}
}

#moyaLoginLogo {
	display: block;
	width: 75px;
	height: 25px;
	position: absolute;
	right: 0;
	top: 50%;
	margin-top: -12.5px;
	background: url(//static.stefna.is/moya/logo/tiny/116_grey_retina.png)center no-repeat;
	background-size: 71px 22px;
	.text-hide();
}

#breadcrumbs {
	margin-top: 20px;
}
