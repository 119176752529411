.contentWrap {
	position:relative;
	width:100%;
	max-width:@site-width;
	padding:0 @grid-gutter-width/2;
	margin:0 auto;
	@media (min-width:@screen-sm) and (max-width:1023px) {
		max-width:750px;
	}
}

.centeredContentWrap {
	max-width:975px;
	margin:0 auto;
}
