/********************
* Grid
*********************/

#formbuilder .grid .invisible {
	color:#aaa;
}

/********************
* Preview
*********************/
#formbuilder .preview_button {
	margin-bottom: 20px;
	font-weight: bold;
	padding-left: 6px;
}
#formbuilder .preview_icon_edit {
	background:url(/_moya/icons/Zoom_In.png) center right no-repeat;
	white-space:nowrap;
}
#formbuilder .preview_icon_preview {
	background:url(/_moya/icons/Write.png) center right no-repeat;
	white-space:nowrap;
}
#formbuilder .preview_icon_loading {
	background:url(/_moya/icons/Archive.png) center right no-repeat;
	white-space:nowrap;
}
/********************
* Canvas
*********************/

#formbuilder .wrapper {
	position:relative;
}

#formbuilder .canvas {
	width:730px;
	float:left;
}
#formbuilder .design_form {
	min-height: 300px;
}
#formbuilder .design_form .elm {
	margin-bottom:10px;
	position:relative;
	border: 1px solid #ddd;
	padding:5px;
	min-height: 25px;
}
#formbuilder .design_form .elm_pagebreak {
	font-size:16px;
	text-align:center;
	min-height:25px;
	border: 1px dashed #ddd;
}
#formbuilder .design_form .elm .controls {
	display:none;
	position:absolute;
	text-align:right;
	top:0px;
	right:0px;
	padding:3px;
	font-size:10px;
}
#formbuilder .design_form .elm .controls:hover {
	cursor:pointer;
}
#formbuilder .design_form .elm:hover {
	background-color: #eee;
	cursor: move;
}
#formbuilder .design_form label:hover {
	cursor: move;
}
#formbuilder .design_form label span:hover {
	cursor: text;
	background-color: #FCEFA1;
}
#formbuilder .design_form .label_edit {
	font-size:11px;
	font-weight:700;
	background-color:#FCEFA1;
	width:200px;
	padding: 5px;
}
#formbuilder .design_form .label_ok {
	margin-left:5px;
	text-decoration:none;
}
#formbuilder .canvas_footer {
	border-top: 1px solid #DDDDDD;
	width:730px;
	padding:10px 0 10px 0;
	clear:left;
}
#formbuilder .empty_placeholder {
	color: #aaa;
}
#formbuilder .elm_payment label.quantity {
	clear: both;
	float: left;
	margin-right: 10px;
}
#formbuilder .elm_payment input {
	float: left;
	width: 35px;
}
#formbuilder .elm_payment .price {
	float: left;
}

/********************
* Palette
*********************/

#formbuilder .item_menu {
	position:fixed;
	right:20px;
	top:50px;
	z-index:9999;
}
#formbuilder .palette {
	border-bottom: 1px solid #aaa;
	margin-bottom:20px;
	display: none;
}

#formbuilder .palette .item {
	padding:8px;
}
#formbuilder .palette .item:hover {
	cursor: move;
}
#formbuilder .palette .collapsed {
	display:none;
}
#formbuilder .palette .icon_go, .palette .icon_down {
	position:absolute;
	top:8px;
	left:5px;
}
#formbuilder .palette .icon_go {
	display:none;
}

#formbuilder .icon_group {
	position:absolute;left:80%;top:5px;
}

#formbuilder .item_group div {
	padding: 8px 0 8px 30px;
}

#formbuilder .item_list .last {
	border-bottom:none;
}

#formbuilder .item_group {
	position:relative;
	cursor:pointer;
}

#formbuilder div.palette_header.open {
	background: url(/_moya/images/formbuilder_palette_header_down_bg.png) no-repeat;
	width: 150px;
	height: 16px;
	text-indent: -9999px;
	cursor: default;
}
#formbuilder div.palette_header.closed {
	background: url(/_moya/images/formbuilder_palette_header_up_bg.png) no-repeat;
	width: 150px;
	height: 36px;
	padding-top: 5px;
	text-transform: uppercase;
	text-align: center;
	cursor: default;
}

/********************
* Palette Settings
*********************/
form#fsettings {
	height: 500px;
}
#formbuilder .settings {
	width:150px;
}
#formbuilder .settings dl {
	font-size:10px;
	padding: 5px 0 0 5px;
}
#formbuilder .settings dd {
	margin: 0 0 6px 0;
}
#formbuilder .settings a {
	padding: 0 0 0 5px;
}
#settings_dialog .elm_radio label {
	margin-right: 10px;
}
#elm_settings_emailAddress label {
	display:none;
}
#elm_settings_confirmVal label {
	display:none;
}

#formbuilder #settings_button {
	margin-bottom: 20px;
	font-weight: bold;
	padding-left: 6px;
}

#formbuilder .settings_icon {
	background:url(/_moya/icons/Settings.png) center right no-repeat;
	white-space:nowrap;
}
#formbuilder div.admin {
	visibility: hidden;
}

/********************
* Status
*********************/

#formbuilder .status {
	border-top: 1px dotted #ddd;
	border-bottom: 1px dotted #ddd;
	margin-bottom:20px;
	color:#333;
	font-size: 90%;
	padding: 10px 0;
}
#formbuilder .status .bold {
	font-weight: 700;
}

/********************
* Submission
*********************/

#formbuilder .submission td {
	vertical-align: top;
}
#formbuilder .submission th {
	text-align: left;
}
#formbuilder .submission ul {
	list-style: circle inside;
	margin:0;
}
#formbuilder .elm_mailinglist {
	position: relative;
	padding-top:10px !important;
}
#formbuilder .elm_mailinglist label {
	position:absolute;
	left:30px;
	top:6px;
}
#formbuilder .item_placeholder, #formbuilder .item_process {
	border: 1px solid #ddd;
	height: 50px;
	margin-bottom:10px;

}
#formbuilder .item_process {
	background: url(/_moya/images/mini-loader.gif) no-repeat center;
}
#formbuilder a.unread {
	font-weight: 700;
}
/********************
* Export
*********************/

#formbuilder .exportOptions {
	text-align:right;
}
#formbuilder .exportOptions li {
	display: inline;
	list-style-type: none;
	padding-left: 20px;
}
#formbuilder .exportOptions img {
	padding-right: 3px;
}

#formbuilder .maxSubmissionsError {
	background-color:#F8CACA;
	border:1px solid #73000E;
	padding:8px;
	margin:8px 0;
	border-radius: 4px;
}

/********************
* Payment
*********************/
#formbuilder a#fallback {
	display: none;
}
#formbuilder .loader {
	text-align: center;
}
#formbuilder .loader h3 {
	font-weight: bold;
	width: 400px;
	text-align: center;
	margin: 0 auto;
}
#formbuilder .loader img {
	margin-top: 50px;
	margin-bottom: 30px;
}

div.elm_text .size_small, .elm_emailAddress .size_small  {
	width:100px;
}
div.elm_text .size_medium, .elm_emailAddress .size_medium {
	width:200px;
}
div.elm_text .size_large, .elm_emailAddress .size_large  {
	width:300px;
}
div.elm_textarea .size_small {
	height:100px;
}
div.elm_textarea .size_medium {
	height:200px;
}
div.elm_textarea .size_large {
	height:300px;
}
div.elm_radio span.lmnt label {
	display:inline;
	border:none;
	height:auto;
}
div.elm_captcha .captchaImg {
	margin-bottom: 5px;
}
div.elm {
	margin-bottom: 10px;
}
#formbuilder div.elm label {
	display:block;
}
div.elm label.hidden {
	display: none;
}
body.admin div.elm label.hidden {
	display: block;
}
div.elm_checkbox label {
	display:inline;
}
div.elm .error {
	font-size:10px;
	background-color:#f8caca;
	border: 1px solid #73000e;
	padding:2px;
}
div.elm_controls {
	margin-top:10px;
}

#formbuilder div.elm_controls {
	clear:left;
}

.elm_multiCheckbox br {
	display:none;
}

fieldset.col2 {
	float:left;
	width:48%;
	margin-right: 2%;
	box-sizing:border-box;
	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box;
}

fieldset.col1 {
	clear:left;
}

fieldset.hideLegend legend {
	display:none;
}

fieldset.hideBorder {
	border: none;
	margin: 0;
	padding: 0;
}
form div.error {
	border:1px solid #73000e;;
	width:50%;
	padding:4px 8px;
	font-size:10px;
	background:#f8caca;
	margin:0;
}

textarea#multiOptions {
	min-height: 150px;
}